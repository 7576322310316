import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import {
  ConfigurableLoggerService,
  LoggerConfig,
} from 'src/app/shared/services/configurable-logger.service';
import { NavigationVisibilityService } from './features/services/navigation-visibility.service';

@Component({
  selector: 'app-root',
  template: `
    <div class="app-container">
      <!-- 代理ログインや会員ログインのステータスを表示するヘッダー。
           普段は表示しない
      -->
      <app-login-status-header></app-login-status-header>
      <!-- ヘッダーの表示制御を追加 -->
      <div class="navigation-header" *ngIf="showNavigationHeader">
        <div class="left-section">
          <!-- 戻るボタン -->
          <img
            src="assets/icons/left-arrow.svg"
            alt="back-button"
            class="back-button"
            (click)="onBackClick()"
          />
        </div>
        <!-- ページタイトル -->
        <h1 class="page-title">{{ pageTitle }}</h1>
        <div class="right-section">
          <!-- ユーザ情報 -->
          <ng-container *ngIf="userInfo$ | async as userInfo">
            <div
              class="user-info"
              [class.visible]="isUserInfoVisible"
              (click)="toggleUserInfo()"
            >
              <span class="display-name">{{ userInfo.display_name }}</span>
              <span class="member-id">{{ userInfo.member_id }}</span>
            </div>
          </ng-container>
        </div>
      </div>

      <main>
        <router-outlet></router-outlet>
        <div
          class="iphone-padding-bottom-spacer"
          [ngClass]="{ 'hidden-spacer': environment.production }"
        >
          main内のスペーサー
        </div>
      </main>
      <app-mobile-footer-navigation
        *ngIf="showNavigationFooter"
      ></app-mobile-footer-navigation>
    </div>
  `,
  styles: [
    `
      @import 'src/styles/scss/variables';

      .app-container {
        width: 100%;
        min-height: 100dvh; // heightをmin-heightに変更
        display: flex;
        flex-direction: column;
      }

      main {
        flex: 1; // main要素が利用可能な空間を適切に使用する
        // background-color: purple;
        background-color: $background-color-main;
      }

      .navigation-header {
        display: grid;
        grid-template-columns: $navigation-header-height 1fr $navigation-header-height;
        align-items: center;
        justify-items: center;
        height: $navigation-header-height;
        background-color: $navigation-header-background-color;
        flex-shrink: 0; // 親コンテナのスペースが不足しても縮小しない
        // 子要素がヘッダーの高さを超えないように設定
        > * {
          max-height: $navigation-header-height;
        }

        .left-section {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }

        .back-button {
          padding: 0.8rem;
          border: none;
          background: none;
          cursor: pointer;
        }

        .page-title {
          margin: 0;
          text-align: center;
          font-size: 20px;
          grid-column: 2;
          line-height: 48px;
        }

        .right-section {
          width: 48px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 8px;
        }

        // ================
        // ユーザ情報
        // ================
        .user-info {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          font-size: 0.8rem;
          color: white;
          transition: color 0.3s ease;
          cursor: pointer;
          .display-name {
            font-weight: bold;
          }

          .member-id {
            opacity: 0.7;
          }
        }

        .user-info.visible {
          color: #000;
        }
      }

      .hidden-spacer {
        color: transparent;
        background-color: transparent;
      }

      app-mobile-footer-navigation {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: $mobile-footer-height;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0.5rem 0;
        background-color: #2d3748;
      }
    `,
  ],
  host: {
    '[class.login-status-header-hidden]': '!showLoginStatusHeader',
    '[class.navigation-header-hidden]': '!showNavigationHeader',
  },
})
export class AppComponent implements OnInit {
  title = environment.service_name;
  showTestOverlay: boolean = false;

  private logger: ConfigurableLoggerService;

  private activeComponent: any;
  pageTitle: string = '';
  userInfo$ = this.authService.loggedInUserInfo$;
  isUserInfoVisible = false;
  showLoginStatusHeader: boolean = true;
  showNavigationHeader: boolean = true;
  showNavigationFooter: boolean = true;
  showScrollToTop = false;

  protected environment = environment;

  constructor(
    private location: Location,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private navigationVisibilityService: NavigationVisibilityService
  ) {
    const loggerConfig: LoggerConfig = {
      enableDebug: true,
      enableInfo: true,
      enableWarn: true,
      enableError: true,
      prefix: 'AppComponent',
      useTimestamp: true,
    };
    this.logger = new ConfigurableLoggerService(loggerConfig);

    // ヘッダーの表示状態を購読
    this.navigationVisibilityService.headerVisibility$.subscribe((visible) => {
      this.showNavigationHeader = visible;
    });

    // フッターの表示状態を購読
    this.navigationVisibilityService.footerVisibility$.subscribe((visible) => {
      this.showNavigationFooter = visible;
    });
  }

  ngOnInit() {
    this.logger.debug('AppComponent ngOnInit');

    // ヘッダーにタイトルを設定
    this.setTitleFromCurrentRoute();

    // ナビゲーションイベントを監視
    this.setupNavigationEventHandlers();
  }

  // ヘッダーにタイトルを設定
  private setTitleFromCurrentRoute() {
    // this.activatedRouteは DI されており、このコンポーネントで使う時には値が入っている
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }

    if (route.snapshot.data['title']) {
      this.logger.debug(
        '初期表示時にタイトルを設定:',
        route.snapshot.data['title']
      );
      this.pageTitle = route.snapshot.data['title'];
    }
  }

  // ナビゲーションイベントを監視するメソッド
  // ナビゲーション（ページ遷移）が発生するたびに実行
  private setupNavigationEventHandlers() {
    this.router.events
      .pipe(
        // NavigationEndイベントのみをフィルタリング
        // (ナビゲーション完了時のイベントのみを処理対象とする)
        filter((event) => event instanceof NavigationEnd),
        // 現在のルート階層の最も深いアクティブなルートを取得
        // (子ルートを持つ場合は最下層のルートを取得する)
        map(() => this.getDeepestActiveRoute())
      )
      .subscribe((route) => {
        // ブラウザのスクロール位置をリセット
        window.scrollTo(0, 0);

        this.updateTitleFromRoute(route);
        // デバッグログを追加
        this.logger.debug('Route data:', route.snapshot.data);
        this.logger.debug('showLoginStatusHeader:', this.showLoginStatusHeader);
        this.logger.debug('showNavigationHeader:', this.showNavigationHeader);

        // showの値をそのまま使用する（デフォルトはtrue）
        this.showLoginStatusHeader =
          route.snapshot.data['showLoginStatusHeader'] ?? true;
        this.showNavigationHeader =
          route.snapshot.data['showNavigationHeader'] ?? true;

        // 設定後の値も確認
        this.logger.debug(
          'After setting - showLoginStatusHeader:',
          this.showLoginStatusHeader
        );

        this.logger.debug(
          'After setting - showNavigationHeader:',
          this.showNavigationHeader
        );
      });
  }

  // 最も深いアクティブなルートを取得するメソッド
  private getDeepestActiveRoute() {
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  // ルートからタイトルを更新するメソッド
  private updateTitleFromRoute(route: ActivatedRoute) {
    if (route.snapshot.data['title']) {
      this.logger.debug(
        'ナビゲーション後にタイトルを設定:',
        route.snapshot.data['title']
      );
      this.pageTitle = route.snapshot.data['title'];
    }
  }

  // 戻るボタンの機能
  onBackClick() {
    if (this.activeComponent?.onBack) {
      this.activeComponent.onBack();
    } else {
      this.location.back();
    }
  }

  // ユーザ情報の表示/非表示を切り替えるメソッド
  toggleUserInfo() {
    this.isUserInfoVisible = !this.isUserInfoVisible;
  }
}
