/**
 * すべてのリクエストに withCredentials: true を設定するインターセプター
 *
 */

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  ConfigurableLoggerService,
  LoggerConfig,
} from '../services/configurable-logger.service';

@Injectable()
export class CredentialsInterceptor implements HttpInterceptor {
  private logger: ConfigurableLoggerService;

  constructor() {
    const loggerConfig: LoggerConfig = {
      enableDebug: environment.debug, // 環境設定に基づいてデバッグログを制御
      enableInfo: true,
      enableWarn: true,
      enableError: true,
      prefix: 'CredentialsInterceptor',
      useTimestamp: true,
    };
    this.logger = new ConfigurableLoggerService(loggerConfig);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // すべてのリクエストに withCredentials: true を設定
    // また、Angularからのリクエストであることを示すカスタムヘッダーを追加
    const modifiedReq = req.clone({
      withCredentials: true,
      // カスタムヘッダーを追加
      // ※ API側ではCORSの設定が必要で、header("Access-Control-Allow-Headers: X-Client-Source");を追加する必要がある。
      setHeaders: {
        'X-Client-Source':
          'a7e172104702eccac324a964d4b3382b175b058b93b6d902b9c5f86db4e74419',
      },
    });

    // 開発環境の場合のみログを出力
    if (environment.enableVerboseLogging) {
      this.logger.log(
        `CredentialsInterceptor: ${req.method}リクエストに対してwithCredentials:trueおよび'X-Client-Source: Angular'を設定`,
        req.url
      );
    }

    return next.handle(modifiedReq).pipe(
      tap(
        (_event: any) => {},
        (error) => {
          // エラーが発生した場合のログ（開発環境のみ）
          if (environment.enableVerboseLogging) {
            console.error(
              `CredentialsInterceptor: ${req.method}リクエストでエラーが発生`,
              req.url,
              error
            );
          }
        }
      )
    );
  }

  /*
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request);
  }
  */
}
