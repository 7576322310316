/**
 *
 *
 *
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { CsrfTokenService } from 'src/app/shared/services/csrf-token.service';

export interface UserInfo {
  member_id: string;
  display_name: string;
  is_authenticated: boolean;
  headquarters_staff_id: string;
  is_proxy_login: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public apiRoot = environment.apiRoot;

  // ログインユーザ情報を保持
  private loggedInUserInfoSubject: BehaviorSubject<UserInfo | null> =
    new BehaviorSubject<UserInfo | null>(null);
  public loggedInUserInfo$ = this.loggedInUserInfoSubject.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private csrfTokenService: CsrfTokenService
  ) {}

  /**
   * オブジェクトが空かどうか確認するユーティリティメソッド
   * @param obj チェック対象のオブジェクト
   * @returns オブジェクトが空ならtrue
   */
  isJsonObjectEmpty(obj: any): boolean {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  /**
   * 文字列が数字のみで構成されているか確認するユーティリティメソッド
   * @param str チェック対象の文字列
   * @returns 数字のみならtrue
   */
  isOnlyDigits(str: string): boolean {
    return /^\d+$/.test(str);
  }

  /**
   * 文字列が英数字のみで構成されているか確認するユーティリティメソッド
   * @param str チェック対象の文字列
   * @returns 英数字のみならtrue
   */
  isAlphanumeric(str: string): boolean {
    return /^[A-Za-z0-9]+$/.test(str);
  }

  /**
   * ユーザの認証状態を確認するメソッド
   * @returns 認証状態（Observable<boolean>）
   */
  checkAuthenticationStatus(): Observable<boolean> {
    return this.http
      .get<UserInfo>(
        `${this.apiRoot}/auth/read-get-authenticated-member-info.php`
      )
      .pipe(
        map((response: UserInfo) => {
          if (response.is_authenticated) {
            this.loggedInUserInfoSubject.next(response);
          } else {
            this.loggedInUserInfoSubject.next(null);
          }
          return response.is_authenticated;
        }),
        catchError((error: HttpErrorResponse) => {
          console.error('認証状態確認中のエラー:', error);
          this.loggedInUserInfoSubject.next(null);
          return throwError(() => new Error('認証状態の確認に失敗しました'));
        })
      );
  }

  /**
   * ログイン処理を行うメソッド
   * @param email ユーザのメールアドレス
   * @param password ユーザのパスワード
   * @returns ログイン結果のObservable
   */
  login(email: string, password: string): Observable<any> {
    const postData = { email, password };
    // <any>は、APIのレスポンスの型を指定しないための型注釈。
    return this.http.post<any>(`${this.apiRoot}/auth/login.php`, postData).pipe(
      tap((response: any) => {
        if (response.message) {
          console.log('AuthService: ログイン成功', response);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        console.error('AuthService: ログインエラー', error);
        return throwError(() => error);
      })
    );
  }

  /**
   * ログアウトを実行し、完了後にCSRFトークンを更新しトップページへ遷移する
   * コールバック関数ではなくObservableを返すため、呼び出し側はsubscribeで成功／失敗を処理します。
   *
   * @returns ログアウト処理のObservable
   */
  logout(): Observable<any> {
    return this.http.post(`${this.apiRoot}/auth/logout.php`, {}).pipe(
      tap((response: any) => {
        // ログアウト成功時に保持しているユーザー情報をクリアする
        this.loggedInUserInfoSubject.next(null);
        console.log('logout() : ログアウトに成功しました。', response);
      }),
      catchError((error: HttpErrorResponse) => {
        console.error('logout() : ログアウトエラー:', error);
        return throwError(() => error);
      }),
      finalize(() => {
        // ログアウト完了後にCSRFトークンを更新し、トップページに遷移する
        this.csrfTokenService.refreshCsrfToken().subscribe({
          next: () => {
            console.log('CSRFトークンを更新しました。');
            this.router.navigate(['/']);
          },
          error: (error) => {
            console.error('CSRFトークン更新エラー:', error);
            this.router.navigate(['/']);
          },
        });
      })
    );
  }

  /**
   * ワンタイムパスワード (OTP) をリクエストするメソッド
   * @param email メールアドレス
   * @returns OTP取得用のHTTP POSTリクエストのObservable
   */
  requestOTP(email: string): Observable<any> {
    const postData = { email };
    return this.http
      .post<any>(`${this.apiRoot}/auth/request-otp.php`, postData)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('OTPリクエスト中のエラー:', error);
          return throwError(() => error);
        })
      );
  }

  /**
   * ユーザの追加情報を登録するメソッド
   * @param data 登録するユーザ情報
   * @returns 登録結果のObservable
   */
  registerAdditionalMemberInfo(data: any): Observable<any> {
    return this.http
      .post<any>(
        `${this.apiRoot}/companies/authenticated/update/auth-update-member-info.php`,
        data
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * エラーハンドリング用のメソッド
   * @param error 発生したエラー
   * @returns エラーObservable
   */
  private handleError(error: HttpErrorResponse) {
    console.error('AuthService : 登録エラー:', error);
    return throwError(() => error);
  }
}
