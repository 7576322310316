import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationVisibilityService {
  private headerVisibilitySubject = new BehaviorSubject<boolean>(true);
  private footerVisibilitySubject = new BehaviorSubject<boolean>(true);

  headerVisibility$ = this.headerVisibilitySubject.asObservable();
  footerVisibility$ = this.footerVisibilitySubject.asObservable();

  hideHeader() {
    this.headerVisibilitySubject.next(false);
  }

  showHeader() {
    this.headerVisibilitySubject.next(true);
  }

  hideFooter() {
    this.footerVisibilitySubject.next(false);
  }

  showFooter() {
    this.footerVisibilitySubject.next(true);
  }

  // 便利メソッド：両方同時に制御
  hideNavigation() {
    this.hideHeader();
    this.hideFooter();
  }

  showNavigation() {
    this.showHeader();
    this.showFooter();
  }
}
